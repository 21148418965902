<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false" :info="info" />
        <v-form ref="logForm" v-model="valid">
            <v-row class="px-5 pt-5">
                <v-col>
                    <v-text-field 
                        :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor" 
                        :loading="loading" 
                        v-model="form.name"
                        dense 
                        outlined 
                        :counter="100" 
                        :rules="[rules.validString,rules.required,rules.size(100)]" 
                        :label="$store.getters.getTextMap().log_name">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-autocomplete
                            v-if="!loading"
                            v-model="form.shift_id"
                            :items="shiftOptions"
                            :label="$store.getters.getTextMap().shifts" 
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                            required
                            :dark="$store.getters.getColorPalette().isDark"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                </v-col>
            </v-row>
            <v-row no-gutters :style="{borderRadius:'10px'}" class="px-5 pt-2">
                <v-col>
                    <v-data-table :headers="fieldHeaders" :items="metaItems" item-key="label" :search="search" :loading="loading" :dark="$store.getters.getColorPalette().isDark"  :style="{border:`1px solid ${$store.getters.getColorPalette().accentCode}`}" loading-text="Loading... Please wait">
                        <template v-slot:top>
                            <v-toolbar flat class="mb-5">
                                <v-row>
                                    <v-col cols="3" class="d-flex align-center">
                                        <v-toolbar-title class="headline">{{ $store.getters.getTextMap().fields }}</v-toolbar-title>
                                    </v-col>
                                    <v-col>
                                        <v-form ref="fieldForm" v-model="fieldValid">
                                            <v-row>
                                                <v-col class="pt-10 d-flex align-center" >
                                                    <v-text-field 
                                                        :dark="$store.getters.getColorPalette().isDark" class="pr-3"
                                                        :color="$store.getters.getColorPalette().inputtextColor" :loading="loading"
                                                        v-model="fieldForm.label"  :rules="[rules.required]"
                                                        :label="$store.getters.getTextMap().field_label" required outlined dense
                                                    ></v-text-field>
                                                    <v-select 
                                                        :dark="$store.getters.getColorPalette().isDark" class="pr-3"
                                                        :color="$store.getters.getColorPalette().inputtextColor" v-if="!loading"
                                                        v-model="fieldForm.field_type" :items="fieldTypes" :label="$store.getters.getTextMap().field_type" 
                                                        item-text="label" item-value="value" required :rules="[rules.required]" outlined dense
                                                        :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                                                    ></v-select>
                                                    <v-autocomplete 
                                                        :dark="$store.getters.getColorPalette().isDark"
                                                        :color="$store.getters.getColorPalette().inputtextColor" class="pr-3"
                                                        v-if="!loading && fieldForm.field_type && fieldForm.field_type == 'list'"
                                                        v-model="fieldForm.field" :items="fieldMetas" :label="$store.getters.getTextMap().choose_list"
                                                        item-text="label" item-value="value" :rules="[rules.required]" outlined dense
                                                        :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                                                    ></v-autocomplete>
                                                    <div class="mt-n6" >
                                                        <v-btn 
                                                            small 
                                                            outlined
                                                            text
                                                            @click="addField" 
                                                            :dark="$store.getters.getColorPalette().isDark"
                                                            :color="$store.getters.getColorPalette().accentCode">
                                                            {{ $store.getters.getTextMap().add }}
                                                            <v-icon small>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>      
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-icon 
                                small 
                                @click="removeField(item)" 
                                :dark="$store.getters.getColorPalette().isDark"
                                color="red">mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-form>
        <v-row class="px-5 pt-2">
            <v-spacer/>
            <v-col cols="auto" class="d-flex" align="right" align-self=center>
                <v-btn  
                    small
                    @click="onSubmit" 
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().submitbtnColor">
                    {{ $store.getters.getTextMap().submit }}<v-icon small class="pl-2">mdi-pencil</v-icon>
                </v-btn>
                <v-btn  
                    class="ml-2"
                    small
                    @click="closeForm" 
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().cancelbtnColor"
                >
                   {{ $store.getters.getTextMap().clear }} <v-icon small class="pl-2">mdi-eraser</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
import axios from 'axios'
export default {
    name: 'CreateShiftMachineUsageLog',
    props: {
        clearForm: {
            type: Boolean,
            default: false
        },
        shiftMachineUsageLog: {
            type: [Object],
            default: null 
        }
    },
    components: {
        InfoAlert
    },
    data() {
        return {
            showDismissibleAlert: false,
            info: '',
            loading: false,
            valid: false,
            fieldValid: false,
            search: null,
            endpoint: 'createShiftMachineUsageLog',
            shiftOptions:[],
            fieldHeaders: [
                {
                    text: "Field Name",
                    value: "label",
                    class:
                        " text-capitalize font-weight-black " +
                        this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                {
                    text: "Field Type",
                    value: "type",
                    class:
                        " text-capitalize font-weight-black " +
                        this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                {
                    text: "Additional Info",
                    value: "field",
                    class:
                        " text-capitalize font-weight-black " +
                        this.$store.getters.getColorPalette().tableHeaderColorName,
                },
                {
                    text: "Action",
                    value: "action",
                    class:
                        " text-capitalize font-weight-black " +
                        this.$store.getters.getColorPalette().tableHeaderColorName,
                },
            ],
            meta: {},
            form: {
                meta: {}
            },
            fieldForm: {},
            rules: FormRules.rules,
            fieldTypes: [
                { label: 'List', value: 'list' },
                { label: 'Text', value: 'text' },
            ],
        }
    },
    mounted() {
        this.getShifts()
        if (this.shiftMachineUsageLog) {
            this.endpoint = 'updateShiftMachineUsageLog'
            this.form = Object.assign({}, this.shiftMachineUsageLog)
            this.meta = Object.assign({}, this.shiftMachineUsageLog.meta)
        } else {
            this.endpoint = 'createShiftMachineUsageLog'
        }
    },
    computed: {
        fieldMetas() {
            let op = []
            let lsts = this.$store.state.lists
            for (let i of lsts) {
                op.push({
                    label: i.name,
                    value: i.list_id
                })
            }
            return op
        },
        metaItems() {
            if (this.meta) {
                return Object.values(this.meta)
            }
            return []
        },
    },
    methods: {
        addField() {
            this.$refs.fieldForm.validate()
            if (this.fieldValid) {
                this.meta[this.fieldForm.label] = {
                    label: this.fieldForm.label,
                    type: this.fieldForm.field_type,
                    field: this.fieldForm.field
                }
                this.meta = Object.assign({}, this.meta)
                this.$refs.fieldForm.reset()
                this.fieldValid = true
            }
        },
        removeField(field) {
            if (field && field.label) {
                delete this.meta[field.label]
                this.meta = Object.assign({}, this.meta)
            }
        },
        getShifts(){
            let op=[]
            this.loading = true;
            axios.get(this.$store.state.api + "shifts",{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    let shifts=response.data.data
                for(let i of shifts){
                    op.push({value:i.shift_id, label: i.name })
                }
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
            this.shiftOptions = op
        },
        onSubmit() {
            this.$refs.logForm.validate()
            if (this.valid) {
                this.loading = true
                this.form['meta'] = this.meta
                axios.post(this.$store.state.api + this.endpoint, this.form, {headers: {Authorization: 'Bearer ' + this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.$store.dispatch('refreshShiftMachineUsageLogs')
                        this.$emit('close')
                    }else{
                        this.info = response.data.msg
                        this.showDismissibleAlert = true
                        this.loading = false
                    }
                })
                .catch(err => {
                    console.error(err)
                    this.info = err
                    this.showDismissibleAlert = true
                    this.loading = false
                });
            }
            this.loading = false
        },
        closeForm(){
            this.$refs.logForm.reset()
            this.$refs.fieldForm.reset()
        }
    },
    watch:{
        clearForm(newVal){
            if(newVal){
                this.closeForm()
            }
        }
    }
}
</script>